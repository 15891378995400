import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useLocation, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { setDefaultLang } from "./app/features/DataSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUserRole } from "./app/features/authSlice";
import AuthLayout from "./components/AuthLayout";
import MainLayout from "./components/MainLayout";
import NoMatch from "./pages/NoMatch";
import Logout from "./pages/Logout";
// Authentication
import Login from "./auth-pages/Login";
import Signup from "./auth-pages/Signup";
import ForgotPassword from "./auth-pages/ForgotPassword";
import ResetPassword from "./auth-pages/ResetPassword";
import VerifyAccount from "./auth-pages/VerifyAccount";
// Client pages
import CollabProfile from "./pages/collaborator/Profile";
import ClientDashboard from "./pages/client/Dashboard";
import Products from "./pages/client/product/Products";
import Profile from "./pages/client/Profile";
import HeliorOne from "./pages/client/product/HeliorOne";
import HeliorPlan from "./pages/client/product/HeliorPlan";
import PurchaseConfirmation from "./pages/client/PurchaseConfirmation";
import PaymentSuccess from "./pages/client/PaymentSuccess";
import PayInstallment from "./pages/client/product/PayInstallment";
import ContractDetail from "./pages/client/product/ContractDetail";
import HOneContractDetails from "./pages/client/product/HOneContractDetails";
// Collaborator pages
import CollaboratorDashboard from "./pages/collaborator/Dashboard";
import ListCollab from "./pages/collaborator/collab/ListCollab";
import SendReferral from "./pages/collaborator/referral/SendReferral";
import ListClient from "./pages/collaborator/client/ListClient";
import Structure from "./pages/collaborator/Structure";
import AddCollaborator from "./pages/collaborator/collab/AddCollaborator";
import ReadCollaborator from "./pages/collaborator/collab/ReadCollaborator";
import UpdateCollaborator from "./pages/collaborator/collab/UpdateCollaborator";
import ReadClient from "./pages/collaborator/client/ReadClient";
import UpdateClient from "./pages/collaborator/client/UpdateClient";
import UpdateProfile from "./pages/collaborator/UpdateProfile";
import UpdateClientProfile from "./pages/client/UpdateProfile";
import Discounts from "./pages/collaborator/Discounts";
import Commissions from "./pages/collaborator/commissions/Commissions";
import Payouts from "./pages/collaborator/payout/Payouts";
import PoolBonus from "./pages/collaborator/commissions/PoolBonus";
import Career from "./pages/collaborator/career/Career";
import Wallet from "./pages/collaborator/wallet/Wallet";

const AllRoutes = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const userRole = useSelector(selectCurrentUserRole);

  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const lang = pathSegments[1];
    dispatch(setDefaultLang(lang));
    if (lang && ["en", "it"].includes(lang)) {
      i18n.changeLanguage(lang);
    } else {
      i18n.changeLanguage("en");
      if (location.pathname === "/") {
        navigate("/login", { replace: true });
      } else {
        navigate("/en" + location.pathname, { replace: true });
      }
    }
  }, [i18n, location.pathname, navigate, dispatch]);

  return (
    <Routes>
      <Route path="/:lang/*">
        <Route element={<AuthLayout />}>
          <Route index element={<Login />} />
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<Signup />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="auth/verify/:token" element={<VerifyAccount />} />
          <Route path="auth/reset-password/:token" element={<ResetPassword />} />
        </Route>
        <Route element={<MainLayout />}>
          {userRole === "collaborator" ? (
            <>
              <Route path="dashboard" element={<CollaboratorDashboard />} />
              <Route path="clients" element={<ListClient />} />
              <Route path="collaborators" element={<ListCollab />} />
              <Route path="add-collaborator" element={<AddCollaborator />} />
              <Route path="client/:userId" element={<ReadClient />} />
              <Route path="collaborator/:userId" element={<ReadCollaborator />} />
              <Route path="client/update/:userId" element={<UpdateClient />} />
              <Route path="profile/update/:userId" element={<UpdateProfile />} />
              <Route path="collaborator/update/:userId" element={<UpdateCollaborator />} />
              <Route path="send-referral" element={<SendReferral />} />
              <Route path="structure" element={<Structure />} />
              <Route path="discounts" element={<Discounts />} />
              <Route path="plan/helior-plan/:contractCode" element={<ContractDetail />} />
              <Route path="plan/helior-one/:contractCode" element={<HOneContractDetails />} />
              <Route path="profile" element={<CollabProfile />} />
              <Route path="products" element={<Products />} />
              <Route path="products/helior-one/:planId" element={<HeliorOne />} />
              <Route path="products/helior-plan/:planId" element={<HeliorPlan />} />
              <Route path="pay-next-amount" element={<PayInstallment />} />
              <Route path=":planType/purchase-confirmation/" element={<PurchaseConfirmation />} />
              <Route path="payment-success" element={<PaymentSuccess />} />
              <Route path="commissions" element={<Commissions />}/>
              <Route path="pool-bonus" element={<PoolBonus />}/>
              {/* <Route path="payouts" element={<Payouts />}/> */}
              <Route path="career" element={<Career />}/>
              <Route path="wallet" element={<Wallet />}/>
            </>
          ) : (
            <>
              <Route path="dashboard" element={<ClientDashboard />} />
              <Route path="products" element={<Products />} />
              <Route path="products/helior-one/:planId" element={<HeliorOne />} />
              <Route path="products/helior-plan/:planId" element={<HeliorPlan />} />
              <Route path="pay-next-amount" element={<PayInstallment />} />
              <Route path=":planType/purchase-confirmation/" element={<PurchaseConfirmation />} />
              <Route path="payment-success" element={<PaymentSuccess />} />
              <Route path="plan/helior-plan/:contractCode" element={<ContractDetail />} />
              <Route path="plan/helior-one/:contractCode" element={<HOneContractDetails />} />
              <Route path="profile" element={<Profile />} />
              <Route path="profile/update/:userId" element={<UpdateClientProfile />}/>
            </>
          )}
        </Route>
        <Route path="*" element={<NoMatch />} />
        <Route path="logout" element={<Logout />} />
      </Route>
    </Routes>
  );
};

export default AllRoutes;
