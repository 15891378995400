import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import SetPageTitle from "../../../components/SetPageTitle";
import BonusChart from "./BonusChart";
import { formatNumberToLocal } from "../../../helpers";
import { useTranslation } from "react-i18next";
import { useCareerDataQuery } from "../../../app/features/apiSlice";
import Loader from "../../../components/Loader";
import Error from "../../../components/Error";

const tableData = [
  {
    level: 1,
    doc: 1000000,
    maxDoc: 50000,
  },
  {
    level: 2,
    doc: 5000000,
    maxDoc: 250000,
  },
  {
    level: 3,
    doc: 10000000,
    maxDoc: 500000,
  },
  {
    level: 4,
    doc: 20000000,
    maxDoc: 1000000,
  },
  {
    level: 5,
    doc: 30000000,
    maxDoc: 1500000,
  },
];

const Career = () => {
  const { t } = useTranslation();
  const sessionId = useRef(Date.now()).current;
  const [collabData, setCollabData] = useState([]);
  const [docPer, setDocPer] = useState({});
  const [total, setTotal] = useState({
    currentMonth: 0,
    progressiveDoc: 0,
  });
  const { data, isLoading, isError, error, isSuccess } = useCareerDataQuery({
    sessionId,
  });

  useEffect(() => {
    if (isSuccess) {
      const docPerData = data.data.filter((item) => item.first_name === "DOC");
      const otherThanDoc = data.data.filter(
        (item) => item.first_name !== "DOC"
      );
      const currentMonth = data?.data.reduce(
        (sum, user) => sum + user.currentMonthDoc,
        0
      );
      const progressiveDoc = data?.data.reduce(
        (sum, user) => sum + user.progressiveDoc,
        0
      );
      if (docPerData.length > 0) {
        setDocPer(docPerData[0]);
      }
      if (otherThanDoc.length > 0) {
        setCollabData(otherThanDoc);
      }
      setTotal({ currentMonth, progressiveDoc });
    }
  }, [isSuccess, data]);

  if (isLoading) return <Loader />;
  if (isError) return <Error error={error} />;

  return (
    <>
      <Card bg="dark1 text-light mb-3">
        <Card.Header>
          <SetPageTitle title="career" />
        </Card.Header>
        <Card.Body>
          <Row>
            <Col className="align-self-end my-2">
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th className="text-light">{t("careerLevel")}</th>
                    <th className="text-light">{t("totalVolumeDOC")}</th>
                    <th className="text-light">{t("maxNumberDOCs")}</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((data, i) => (
                    <tr key={i}>
                      <td className="text-light">{data.level}</td>
                      <td className="text-light">
                        {formatNumberToLocal(data.doc)}
                      </td>
                      <td className="text-light">
                        {formatNumberToLocal(data.maxDoc)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
            <Col className="my-2">
              <h6 className="text-center">
                {t("teamManagerCompensationPlan")}
              </h6>
              <BonusChart />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card bg="dark1 text-light mb-3">
        <Card.Header>{t("currentCareerLevel")}</Card.Header>
        <Card.Body>
          <h6 className="mt-3">{t("directLines")}</h6>
          <Table className="career" responsive>
            <thead>
              <tr>
                <th></th>
                {collabData.map((item, i) => (
                  <th key={i}>
                    {item.first_name} {item.last_name}
                  </th>
                ))}
                <th>DOC Pers</th>
                <th>{t("total")}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="bg-success">
                  {t("DOCVolume")} <br />
                  {t("currentMonth")}
                </td>
                {collabData.map((item, i) => (
                  <td key={i}>{item.currentMonthDoc}</td>
                ))}
                <td className={"tbl-bg-dark px-0"}>
                  <div>{docPer.currentMonthDoc || 0}</div>
                </td>
                <td>{total.currentMonth}</td>
              </tr>
              <tr className="add-space">
                <td className="bg-success">
                  {t("ProgressiveDOCVolume")} <br />
                  {t("with50Rule")}
                </td>
                {collabData.map((item, i) => (
                  <td key={i}>{item.progressiveDoc}</td>
                ))}
                <td className={"tbl-bg-dark px-0"}>
                  <div>{docPer.progressiveDoc || 0}</div>
                </td>
                <td>{total.progressiveDoc}</td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
};

export default Career;
