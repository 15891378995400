import React, { useState } from "react";
import { Button, Card, Form, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useRequestForPayoutMutation } from "../../app/features/apiSlice";
import { toastError, toastSuccess } from "../../helpers";

const RequestPayout = ({ balance }) => {
  const { t } = useTranslation();
  const { lang } = useParams();
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(0);

  const [requestForPayout] = useRequestForPayoutMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (amount <= 0) {
        toastError("", t("amountCannotBeZero"));
        setLoading(false);
        return false;
      }
      const data = { amount, language: lang };
      const response = await requestForPayout(data).unwrap();
      if (response?.error) {
        toastError(response, t("error.serverNotResponding"));
      } else {
        toastSuccess(response?.message);
      }
    } catch (err) {
      toastError(err, t("error.serverNotResponding"));
    }
    setLoading(false);
  };
  return (
    <>
      <Card bg="dark1" className="my-3 text-light">
        <Card.Header>
          {t("availableForWithdrawal")}: {balance}
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <InputGroup className="mb-1" size="sm">
              <Form.Control
                type="text"
                name="amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                min="0"
                style={{ maxWidth: "12rem" }}
              />
              <Button
                type="submit"
                variant="outline-warning"
                disabled={loading}
              >
                {t("request")}
              </Button>
            </InputGroup>
            <Form.Text className="text-danger">
              {t("noteForPaymentRequest")}
            </Form.Text>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default RequestPayout;
