import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { selectUserData } from "../../app/features/DataSlice";
import Swal from "sweetalert2";
import { selectCurrentUserRole } from "../../app/features/authSlice";

const Sidebar = () => {
  const { lang } = useParams();
  const { t } = useTranslation();
  const userData = useSelector(selectUserData);
  const userRole = useSelector(selectCurrentUserRole);
  const navigate = useNavigate();

  const clientNavItems = [
    {
      icon: "fas fa-house",
      link: "dashboard",
      name: "dashboard",
    },
    {
      icon: "fas fa-bag-shopping",
      link: "products",
      name: "products",
    },
    {
      icon: "fas fa-user",
      link: "profile",
      name: "profile",
    },
  ];

  const collabNavItems = [
    {
      icon: "fas fa-house",
      link: "dashboard",
      name: "dashboard",
    },
    {
      icon: "fas fa-users",
      link: "clients",
      name: "clients",
    },
    {
      icon: "fas fa-users-gear",
      link: "collaborators",
      name: "collaborators",
    },
    {
      icon: "fas fa-bag-shopping",
      link: "products",
      name: "products",
    },
    {
      icon: "fas fa-share",
      link: "send-referral",
      name: "sendReferral",
    },
    {
      icon: "fas fa-ticket",
      link: "discounts",
      name: "discounts",
    },
    {
      icon: "fa fa-usd",
      link: "commissions",
      name: "commissions"
    },
    {
      icon: "fa fa-usd",
      link: "pool-bonus",
      name: "poolBonus"
    },
    // {
    //   icon: "fa fa-hand-holding-dollar",
    //   link: "payouts",
    //   name: "payouts"
    // },
    {
      icon: "fa fa-project-diagram",
      link: "structure",
      name: "structure"
    },
    {
      icon: "fa-solid fa-wrench",
      link: "career",
      name: "career"
    },
    {
      icon: "fas fa-user",
      link: "profile",
      name: "profile",
    },
    {
      icon: "fas fa-wallet",
      link: "wallet",
      name: "wallet",
    },
  ];

  const handleLogout = () => {
    Swal.fire({
      title: t("logoutAlert"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#b8932c",
      cancelButtonColor: "#282828",
      cancelButtonText: t("cancelButtonText"),
      confirmButtonText: t("yes"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        navigate(`/${lang}/logout`);
      }
    });
  };

  return (
    <div id="layoutSidenav_nav">
      <nav
        className="sb-sidenav accordion sb-sidenav-dark"
        id="sidenavAccordion"
      >
        <div className="navbar-brand ps-3 pb-3">
          <img
            alt=""
            src="/helior-logo-light.svg"
            width="160"
            className="d-block"
          />
        </div>
        <div className="sb-sidenav-menu">
          {userRole === "customer" && (
            <div className="nav">
              {clientNavItems.map((item, i) => (
                <NavLink
                  className="nav-link"
                  to={`/${lang}/${item.link}`}
                  key={i}
                >
                  <div className="sb-nav-link-icon">
                    <i className={item.icon}></i>
                  </div>{" "}
                  {t(`sidebar.${item.name}`)}
                </NavLink>
              ))}
              <button className="nav-link" onClick={handleLogout}>
                <div className="sb-nav-link-icon">
                  <i className="fa-solid fa-right-from-bracket"></i>
                </div>{" "}
                {t(`sidebar.logout`)}
              </button>
            </div>
          )}
          {userRole === "collaborator" && (
            <div className="nav">
              {collabNavItems.map((item, i) => (
                <NavLink
                  className="nav-link"
                  to={`/${lang}/${item.link}`}
                  key={i}
                >
                  <div className="sb-nav-link-icon">
                    <i className={item.icon}></i>
                  </div>{" "}
                  {t(`sidebar.${item.name}`)}
                </NavLink>
              ))}
              <button className="nav-link" onClick={handleLogout}>
                <div className="sb-nav-link-icon">
                  <i className="fa-solid fa-right-from-bracket"></i>
                </div>{" "}
                {t(`sidebar.logout`)}
              </button>
            </div>
          )}
        </div>
        <div className="sb-sidenav-footer d-flex flex-direction-row align-items-center">
          <i className="fa fa-user-circle me-2" />
          <strong>
            {userData?.first_name} {userData?.last_name}
          </strong>
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;
