// https://reactdatepicker.com/#example-custom-month
// https://reactdatepicker.com/#example-date-range-using-input-with-clear-button

import React, { useEffect, useRef, useState } from "react";
import SetPageTitle from "../../../components/SetPageTitle";
import SmallCards from "../../../components/dashboard/SmallCards";
import { Badge, Card, Form, Row } from "react-bootstrap";
import { formatNumberToLocal } from "../../../helpers";
import wallet from "../../../assets/images/wallet.webp";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import network from "../../../assets/images/network.webp";

import "react-datepicker/dist/react-datepicker.css";
import { useGetEwalletDataQuery } from "../../../app/features/apiSlice";
import Loader from "../../../components/Loader";
import Error from "../../../components/Error";
import moment from "moment";
import "moment/locale/it";
import { useParams } from "react-router";
import RequestPayout from "../../../components/payout/RequestPayout";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const Wallet = () => {
  const dt = useRef();
  const { lang } = useParams();
  lang === "it" ? moment.locale("it") : moment.locale("en");
  const { t } = useTranslation();
  const sessionId = useRef(Date.now()).current;
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [queryParams, setQueryParams] = useState({
    startDate: null,
    endDate: null,
    type: "",
    status: "",
  });
  const [transactionHistory, setTransactionHistory] = useState([]);
  const { data, isLoading, isError, error, refetch } = useGetEwalletDataQuery(
    {
      sessionId,
      queryParams,
    }
    // { skip: !queryParams.startDate || !queryParams.endDate } // Skip if params are not set
  );

  const handleDateRangeChange = (update) => {
    const startDate = update[0] ?? new Date();
    const endDate = update[1] ?? new Date();
    setDateRange(update);
    setQueryParams((prev) => ({
      ...prev,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    }));

    refetch();
  };

  const handleStatusChange = (e) => {
    const { name, value } = e.target;
    console.log({ name, value });
    setQueryParams((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (!isLoading && data?.transactionHistory) {
      setTransactionHistory(data.transactionHistory);
    }
  }, [data, isLoading]);

  if (isLoading) return <Loader />;
  if (isError) return <Error error={error} />;

  return (
    <>
      <SetPageTitle title="sidebar.wallet" display={false} />
      <Row>
        <SmallCards
          imgSrc={wallet}
          title={t("currentBalance")}
          number={`${formatNumberToLocal(data.currentBalance || 0)}€`}
        />
        <SmallCards
          icon={<i className="fas fa-calculator"></i>}
          title={t("totalAccumulated")}
          number={`${formatNumberToLocal(data?.totalAccumulated || 0)}€`}
        />
        <SmallCards
          icon={<i className="fas fa-calendar"></i>}
          title={t("productionMonth")}
          number={`${moment(data?.productionMonth?.from).format(
            "Do MMM"
          )} - ${moment(data?.productionMonth?.to).format("Do MMM")}`}
        />
        <SmallCards
          imgSrc={network}
          title={t("nextPaymentRequestDate")}
          number={`${moment(data?.nextPaymentRequestDate?.fromDate).format(
            "Do MMM"
          )} - ${moment(data?.nextPaymentRequestDate?.toDate).format(
            "Do MMM"
          )}`}
        />
      </Row>
      <RequestPayout
        balance={`${formatNumberToLocal(data.currentBalance || 0)}€`}
      />
      <Card bg="dark1" className="text-light">
        <Card.Header>{t("transactions")}</Card.Header>
        <Card.Body>
          <div className="d-flex justify-content-end align-items-center">
            <Form.Group className="d-flex flex-column form-calendar">
              <Form.Label>{t("status")}</Form.Label>
              <Form.Select
                defaultValue={queryParams.status}
                size="sm"
                className="ms-1"
                name="status"
                onChange={(e) => handleStatusChange(e)}
              >
                <option value={""}>{t("status")}</option>
                <option value="Completed">{t("completed")}</option>
                <option value="Pending">{t("pending")}</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="ms-1 d-flex flex-column form-calendar">
              <Form.Label>{t("paymentType")}</Form.Label>
              <Form.Select
                defaultValue={queryParams.type}
                size="sm"
                className="ms-1"
                name="type"
                onChange={(e) => handleStatusChange(e)}
              >
                <option value={""}>{t("paymentType")}</option>
                <option value="Commission">{t("commission")}</option>
                <option value="Bonus">{t("bonus")}</option>
                <option value="Withdraw">{t("withdraw")}</option>
              </Form.Select>
            </Form.Group>
            {/* <Form.Group className="ms-2 d-flex flex-column form-calendar">
              <Form.Label>{t("productionMonth")}</Form.Label>
              <DatePicker
                // selected={new Date()}
                showMonthYearPicker
                dateFormat="MM/yyyy"
                className="form-control form-control-sm"
              />
            </Form.Group> */}
            <Form.Group className="ms-2 d-flex flex-column form-calendar">
              <Form.Label>{t("dateRange")}</Form.Label>
              <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  handleDateRangeChange(update);
                }}
                className="form-control form-control-sm"
                // isClearable={true}
              />
            </Form.Group>
          </div>
          <DataTable
            value={transactionHistory}
            sortMode="multiple"
            paginator
            rows={25}
            rowsPerPageOptions={[10, 25, 50]}
            ref={dt}
            size={"small"}
            showGridlines
            stripedRows
            removableSort
          >
            <Column
              field="createdAt"
              header={t("createdAt")}
              body={(rowData) => moment(rowData.createdAt).format("YYYY-MM-DD")}
            />
            <Column
              field="updatedAt"
              header={t("updatedAt")}
              body={(rowData) => moment(rowData.updatedAt).format("YYYY-MM-DD")}
            />
            <Column
              field="type"
              header={t("paymentType")}
              body={(rowData) =>
                rowData.type !== "Withdraw" ? (
                  t(`${rowData.type}`)
                ) : (
                  <span className="text-danger">{t(`${rowData.type}`)}</span>
                )
              }
            />
            <Column
              field="amount"
              header={t("amount")}
              // body={(rowData) => formatNumberToLocal(rowData.amount) + "€"}
              body={(rowData) =>
                rowData.type !== "Withdraw" ? (
                  `${formatNumberToLocal(rowData.amount) + "€"}`
                ) : (
                  <span className="text-danger">
                    -{formatNumberToLocal(rowData.amount) + "€"}
                  </span>
                )
              }
            />
            <Column
              field="status"
              header={t("status")}
              body={(rowData) =>
                rowData.status === "Completed" ? (
                  <Badge bg="success">{t(`${rowData.status}`)}</Badge>
                ) : (
                  t(`${rowData.status}`)
                )
              }
            />
            <Column />
          </DataTable>
        </Card.Body>
      </Card>
    </>
  );
};

export default Wallet;
