import React, { useRef, useEffect } from "react";
import * as d3 from "d3";

const data = [
  {
    title: "Iron",
    label: "10000",
    desc: "10 collab",
    value: 20,
    text: "0.1% ric",
    capColor: "#595959",
  },
  {
    title: "Steel",
    label: "50000",
    desc: "20 collab",
    value: 30,
    text: "0.2% ric",
    capColor: "#D9D9D9",
  },
  {
    title: "Bronze",
    label: "100000",
    desc: "40 collab",
    value: 40,
    text: "0.3% ric",
    capColor: "#b08d57",
  },
  {
    title: "Silver",
    label: "200000",
    desc: "80 collab",
    value: 50,
    text: "0.4% ric",
    capColor: "#9A9A9A",
  },
  {
    title: "Gold",
    label: "300000",
    desc: "150 collab",
    value: 60,
    text: "0.5% ric",
    capColor: "#b8932c",
  },
];

const BonusChart = () => {
  const svgRef = useRef();

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    const width = 600;
    const height = 400;

    svg.attr("width", width).attr("height", height);

    const add3DBar = (parent, xPos, yPos, barWidth, barHeight, depth, d) => {
      const g = parent
        .append("g")
        .attr("transform", `translate(${xPos}, ${yPos})`);

      // 1st Floor Front wall
      g.append("path")
        .style("fill", "#FFD700")
        .attr("stroke", "black")
        .attr("stroke-width", 0.3)
        .attr("d", `M 0,0 V ${-barHeight} H ${barWidth} V 0 H 0 Z`);

      // 2nd Floor Front wall
      g.append("path")
        .style("fill", d.capColor)
        .attr("stroke", "black")
        .attr("stroke-width", 0.3)
        .attr(
          "d",
          `M 0,${-barHeight - barWidth} V ${-barHeight} H ${barWidth} V ${
            -barHeight - barWidth
          } H 0 Z`
        );

      // Roof
      g.append("path")
        .attr(
          "d",
          `M 0,${-barHeight - barWidth} 
         L ${depth},${-barHeight - barWidth - depth} 
         H ${depth + barWidth} 
         L ${barWidth},${-barHeight - barWidth} 
         Z`
        )
        .style("fill", d.capColor)
        .attr("stroke", "black")
        .attr("stroke-width", 0.3);

      // 1st Floor Side wall
      g.append("path")
        .style("fill", "#F5DEB3")
        .attr("stroke", "black")
        .attr("stroke-width", 0.3)
        .attr(
          "d",
          `M ${barWidth},0 L ${barWidth + depth},${-depth} L ${
            barWidth + depth
          },${-depth - barHeight} L ${barWidth},${-barHeight} Z`
        );

      // 2nd Floor Side wall
      g.append("path")
        .style("fill", d.capColor)
        .attr("stroke", "black")
        .attr("stroke-width", 0.3)
        .attr(
          "d",
          `M ${barWidth},${-barHeight} 
          L ${barWidth + depth},${-depth - barHeight} 
          L ${barWidth + depth},${-depth - barHeight - barWidth}
          L ${barWidth},${-barHeight - barWidth} 
          Z`
        );

      // Draw label
      g.append("text")
        .attr("x", barWidth / 2)
        .attr("y", 20)
        .attr("text-anchor", "middle")
        .attr("font-size", "14px")
        .attr("fill", "#fff")
        .text(d.label);

      // Draw Desc
      g.append("text")
        .attr("x", barWidth / 2)
        .attr("y", 35)
        .attr("text-anchor", "middle")
        .attr("font-size", "11px")
        .attr("fill", "#fff")
        .text(d.desc);

      g.append("text")
        .attr("x", barWidth / 2)
        .attr("y", -(barHeight + 30))
        .attr("text-anchor", "middle")
        .attr("font-size", "20px")
        .attr("fill", "#FFF")
        .text(d.value / 10 - 1);

      g.append("text")
        .attr("x", barWidth / 2)
        .attr("y", -40)
        .attr("text-anchor", "middle")
        .attr("font-size", "12px")
        .text(`${d.value / 10 - 1}%`);

      // Draw text on bar
      g.append("text")
        .attr("x", barWidth / 2)
        .attr("y", -25)
        .attr("text-anchor", "middle")
        .attr("font-size", "12px")
        .text(d.text);

      // Draw title on bar
      g.append("text")
        .attr("x", barWidth / 2)
        .attr("y", -10)
        .attr("text-anchor", "middle")
        .attr("font-size", "12px")
        .attr("stroke-width", 0.5)
        .text(d.title);
    };

    const barWidth = 70;
    const barSpacing = 30;
    const barHeightFactor = 4;

    data.forEach((d, i) => {
      const x = i * (barWidth + barSpacing) + 100;
      const y = height - 50;
      const barHeight = d.value * barHeightFactor;

      add3DBar(svg, x, y, barWidth, barHeight, 10, d);
    });
  }, []);

  return (
    <div className="barchart">
      <svg ref={svgRef}></svg>
    </div>
  );
};

export default BonusChart;
